<template>
  <div class="toggle-switch-wrap">
    <label
      v-if="direction == 'right'"
      :direction="direction"
      :for="id"
      :class="{ disabled: disabled }"
    >
      {{ content }}
    </label>
    <div class="switch-icon">
      <input
        type="checkbox"
        :id="id"
        v-model="switchValue"
        :disabled="disabled"
        @click="onSwitch"
      />
      <label
        for="toggle-switch"
        class="slider"
        :class="{ disabled: disabled }"
        @click="onSwitch"
      ></label>
    </div>
    <label
      v-if="direction == 'left'"
      :direction="direction"
      :for="id"
      :class="{ disabled: disabled }"
    >
      {{ content }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    id: {
      type: String,
      default: 'toggle-switch'
    },
    direction: {
      type: String,
      default: 'left' // 'right'|'left'
    },
    content: {
      type: String,
      default: ''
    },
    value: {
      type: [Boolean, Number],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      switchValue: false
    }
  },
  methods: {
    setSwitchValue(value) {
      this.switchValue = value
    },
    onSwitch() {
      if (this.disabled) return

      this.switchValue = !this.switchValue
      this.$emit('switch', this.switchValue)
      this.$emit('input', this.switchValue)
    }
  },
  mounted() {
    this.setSwitchValue(this.value)
  },
  watch: {
    value() {
      this.setSwitchValue(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
$ThumbSize: 1rem;
* {
  box-sizing: border-box;
}
.toggle-switch-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $color_FFF;

  label {
    font-size: px2rem(24);
    // font-weight: 300;
    white-space: nowrap;
    cursor: pointer;

    &[direction='right'] {
      margin-right: 1rem;
      margin-left: 0;
    }

    &[direction='left'] {
      margin-right: 0;
      margin-left: 0.5rem;
    }

    &.disabled {
      cursor: unset;
    }
  }
  .switch-icon {
    width: px2rem(32);
    padding: 0 px2rem(2);
    // background-color: #00f;
    .slider {
      position: relative;
      display: inline-block;
      height: px2rem(10);
      width: 100%;
      margin-right: 0.5rem;
      // border: 1px solid $color_9D9D9D;
      border-radius: px2rem(5);
      background-color: $color_switch_off;
      cursor: pointer;
      transition: all $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);

      // dot
      &:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: calc(-0.25rem + 0.5px);
        display: block;
        border: 1px solid $color_switch_thumb_bdc;
        border-radius: 50%;
        box-shadow: 0px px2rem(2) px2rem(2) rgba(0, 0, 0, 0.2);
        width: $ThumbSize;
        height: $ThumbSize;
        background-color: $color_switch_thumb;
        transition: left $AnimateSec;
      }
    }

    input {
      display: none;
      &:disabled ~ .slider {
        // opacity: 0.2;
        cursor: unset;
      }

      &:checked ~ .slider {
        background-color: $color_switch_on;
      }
      &:checked ~ .slider:after {
        left: calc(100% - $ThumbSize);
      }
    }
  }
}
</style>
